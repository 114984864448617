<template>
<div>
    刷新对象视图
</div>
</template>

<script>
    import * as cacheApi from "./api.js";
    export default {
        name: "viewRefresh",
        mounted() {
            document.getElementById("lightning-loading").style.display = "none";
            this.init()
        },
        methods:{
            init() {
                let url = window.location.href
                // 获取从？开始的url部分
                let searchUrl = url.split("?")[1];
                let paramsObj = this.$qs.parse(searchUrl);
                let params = {
                    obj: paramsObj.obj ? paramsObj.obj : "AllOBJ"
                }
                cacheApi.viewRefresh(params).then(res => {
                    if (res.result) {
                        this.$message.success('刷新成功');
                    } else {
                        this.$message.info(res.returnInfo);
                    }
                }).catch(error=>{
                    this.$message.error(error.returnInfo);
                })
            }
        }
    }
</script>

<style scoped>

</style>
